import React, { FC, createContext, useCallback } from 'react';
import { SWRConfig, ConfigInterface } from 'swr';
import transformXmlResponse from './xml/transformResponse';
import transformJsonResponse from './json/transformResponse';

type FetchFunction = (
  input: RequestInfo,
  init?: RequestInit,
) => Promise<Response>;

export type SwrOptions = Omit<ConfigInterface, 'fetcher'>;

export type AugmentedFetchFunction = (
  input: RequestInfo,
  init?: RequestInit,
) => Promise<{ data?: any; error?: HookshotError; response: Response }>;

export class HookshotError<Data = any> extends Error {
  response: Response;
  data?: Data;
  constructor(response: Response, data?: any) {
    super();
    this.response = response;
    this.data = data;
  }
}

export const ForgeHooksContext = createContext<{
  fetcher?: AugmentedFetchFunction;
  defaultFormat?: 'xml' | 'json';
}>(null);

const useFetcher = (customFetch: typeof fetch) => {
  const fetcher: AugmentedFetchFunction = useCallback(
    async (url: string, options: any) => {
      // perform fetch
      const response: Response = await customFetch(url, {
        credentials: 'include',
        headers: {},
        ...options,
      });
      // Parse response
      try {
        // We determine if we should use JSON based on the content-type of the response.
        const useJson =
          response.headers.get('Content-Type') &&
          response.headers.get('Content-Type')?.includes('application/json');
        const data = useJson
          ? transformJsonResponse(await response.json())
          : await transformXmlResponse(await response.text());
        return response.ok
          ? { data, response }
          : { error: new HookshotError(response, data), response };
      } catch (err) {
        console.error('Error while parsing response: ', err);
        return { error: new HookshotError(response), response };
      }
    },
    [customFetch],
  );

  return fetcher;
};

export type JanusHookshotProviderProps = {
  customFetch: FetchFunction;
  swrOptions?: SwrOptions;
  /** The default format to send data in */
  defaultFormat?: 'xml' | 'json';
};

/**
 * JanusHookshotProvider
 * Special Hookshot provider for use in Janus codebase
 * accepts an existing custom fetch function
 */
export const JanusHookshotProvider: FC<JanusHookshotProviderProps> = ({
  customFetch,
  defaultFormat,
  children,
  swrOptions,
}) => {
  const fetcher = useFetcher(customFetch);

  return (
    <ForgeHooksContext.Provider
      value={{
        fetcher,
        defaultFormat,
      }}
    >
      <SWRConfig
        value={{
          fetcher,
          ...swrOptions,
        }}
      >
        {children}
      </SWRConfig>
    </ForgeHooksContext.Provider>
  );
};
