import { useCallback, useContext } from 'react';
import transformXmlRequest, {
  TransformXMLRequestOptions,
} from '../../xml/transformRequest';
import * as utils from '../../utils';
import useBoundFetch, { BoundFetchResult } from '../useBoundFetch';
import { ForgeHooksContext, HookshotError } from '../../ForgeHooksProvider';

export interface UseWriteOptions<Response, Input = any> {
  /**
   * A function that returns prepares the request data for XML serialization
   */
  transformRequest?: (data: Input) => any;
  /**
   * A function that returns the transformed response
   */
  transformResponse?: (data: any) => Response;

  /**
   * A function that returns the transformed error
   */
  transformError?: (error: HookshotError) => HookshotError;
  /**
   * Supply additional parameters to fetch
   */
  fetchOptions?: Partial<RequestInit>;
  /**
   * Override options on the XML transformer. Only use this if you know what you're doing.
   */
  xmlRequestConversionOptions?: TransformXMLRequestOptions;
  /**
   * Specify whether the request should be sent using XML or JSON
   */
  requestFormat?: 'xml' | 'json';
}

type WriteResult<R> = Promise<Omit<BoundFetchResult<R>, 'error'>>;

export type WriteFn<V, R> = (
  vars: V,
  moreFetchOptions?: Partial<RequestInit>,
) => WriteResult<R>;

type GetRequestBody<V> = V extends { input: any } ? V['input'] : any;

/**
 * Returns a function that can be used to perform a PUT/POST/PATCH/DELETE request on a remote resource.
 * @param path The URL that you want to perform your request on. Typically you should pass in `utils.makeUrl(url, vars)` here.
 * @param method API method to use. POST/PUT/PATCH etc.
 * @param options
 * @example
 * ```tsx
 * const writeFn = useWrite<RequestVars, ResponseType>(url, method, { transformRequest, transformResponse, ...writeOptions });
 * ```
 * @see https://forge-hookshot-docs.apps.lab1.ocp.bandwidth.com/docs/custom-hooks#custom-write-hooks
 */
const useWrite = <Vars = any, ResponseType = any>(
  path: string | ((vars: Vars) => string),
  method: string,
  options: UseWriteOptions<ResponseType, GetRequestBody<Vars>> = {},
): WriteFn<Vars, ResponseType> => {
  const { defaultFormat } = useContext(ForgeHooksContext);
  const {
    transformRequest,
    transformResponse,
    transformError,
    fetchOptions,
    xmlRequestConversionOptions,
    requestFormat: requestFormatOption,
  } = options;
  const requestFormat = requestFormatOption || defaultFormat;
  const boundFetch = useBoundFetch<ResponseType>({
    transformResponse,
    transformError,
  });
  return useCallback(
    async (vars: Vars, moreFetchOptions?: Partial<RequestInit>) => {
      const finalUrl =
        typeof path === 'string' ? utils.makeUrl(path, vars) : path(vars);
      const generateBody = () => {
        if (!vars || !vars['input']) {
          return null;
        }
        const transformedBody = transformRequest
          ? transformRequest(vars['input'])
          : vars['input'];

        if (
          xmlRequestConversionOptions?.skipXmlConversion ||
          requestFormat === 'json'
        ) {
          return typeof transformedBody === 'object'
            ? JSON.stringify(transformedBody)
            : String(transformedBody);
        }
        return transformXmlRequest(
          transformedBody,
          xmlRequestConversionOptions,
        );
      };
      const { error, ...fetchResult } = await boundFetch(finalUrl, {
        method,
        body: generateBody(),
        credentials: 'include',
        headers: {},
        ...fetchOptions,
        ...moreFetchOptions,
      });
      if (error) {
        throw error;
      }
      return fetchResult;
    },
    [boundFetch],
  );
};

export default useWrite;
