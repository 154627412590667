import xmljs from 'xml-js';
import { camelCase } from 'change-case';
import { omit, isEmpty, isPlainObject } from 'lodash';

/** the base configuration for XML conversion */
const convertBaseOptions = {
  compact: true,
  attributeNameFn: name => camelCase(name),
  elementNameFn: name => camelCase(name),
  /**
   * Collapse text attributes into the value of the node
   * itself.
   */
  textFn: function(value, parentElement) {
    try {
      // Pulled from here: https://github.com/nashwaan/xml-js/issues/53
      const parentOfParentKeys = Object.keys(parentElement._parent);
      const keyNo = parentOfParentKeys.length;
      const keyName = parentOfParentKeys[keyNo - 1];
      const arrOfKey = parentElement._parent[keyName];
      const arrOfKeyLen = arrOfKey.length;
      if (arrOfKeyLen > 0) {
        const arr = arrOfKey;
        const arrIndex = arrOfKey.length - 1;
        arr[arrIndex] = value;
      } else {
        parentElement._parent[keyName] = value;
      }
    } catch (e) {
      // do nothing
    }
  },
};

/** removes some unnecessary attributes from XML output */
const removeAttributes = (node: any) =>
  omit(node, ['_attributes', '_declaration']);

/**
 * Recursively traverse the response body tree and
 * strip off XML serialization artifacts
 */
const traverseAndTransform = (node: any) => {
  try {
    if (isPlainObject(node) && isEmpty(node)) {
      return null;
    }

    // scalar values & null
    if (
      node === null ||
      node === undefined ||
      typeof node !== 'object' ||
      node instanceof Date
    ) {
      return node;
    }

    if (node instanceof Array) {
      return node.map(item => traverseAndTransform(item));
    }

    // object values
    const processedNode = removeAttributes(node);

    if (processedNode instanceof Array) {
      return processedNode.map(item => traverseAndTransform(item));
    }

    // finally, traverse all children and transform them as well
    return Object.keys(processedNode).reduce(
      (acc, subKey) => ({
        ...acc,
        [subKey]: traverseAndTransform(processedNode[subKey]),
      }),
      {},
    );
  } catch (error) {
    console.error('Error processing node:');
    console.error({ node });
    throw error;
  }
};

export default (body: any) => {
  if (!body) {
    return body;
  }

  const json = xmljs.xml2js(body, convertBaseOptions);
  return traverseAndTransform(removeAttributes(json));
};
