import { useCallback, useContext } from 'react';
import { ForgeHooksContext, HookshotError } from '../ForgeHooksProvider';
import { unwrapOutermostKey } from '../utils';

type BoundFetchFunction<R> = (
  url: RequestInfo,
  moreFetchOptions?: RequestInit,
) => Promise<BoundFetchResult<R>>;

export interface BoundFetchResult<R> {
  data?: R;
  response?: Response;
  error?: HookshotError;
}

type Transforms<R> = {
  transformResponse: ((data: any) => R) | null;
  transformError: ((error: HookshotError) => HookshotError) | null;
};

const defaultTransformError = (err: HookshotError): HookshotError =>
  err ? new HookshotError(err.response, unwrapOutermostKey(err.data)) : err;

const useBoundFetch = <R = any>(
  transforms: Transforms<R>,
  options?: RequestInit,
) => {
  const {
    transformResponse,
    transformError = defaultTransformError,
  } = transforms;
  const { fetcher } = useContext(ForgeHooksContext);
  const boundFetcher = useCallback(
    async (
      url: string,
      fetchOptions?: RequestInit,
    ): Promise<BoundFetchResult<R>> => {
      const result = await fetcher(url, { ...options, ...fetchOptions });
      return {
        ...result,
        data: transformResponse
          ? transformResponse(result && result.data)
          : result && result.data,
        error: transformError
          ? transformError(result && result.error)
          : result && result.error,
      };
    },
    [fetcher, transformResponse, options],
  );

  return boundFetcher as BoundFetchFunction<R>;
};

export default useBoundFetch;
