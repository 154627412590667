import { isArray, isObject } from 'lodash';
import { camelCase } from 'change-case';

// Converts keys to camel case
const camelKeys = (obj: any) => {
  if (isArray(obj)) {
    return obj.map(i => camelKeys(i));
  } else if (isObject(obj)) {
    return Object.keys(obj).reduce((n, k) => {
      n[camelCase(k)] = camelKeys(obj[k]);
      return n;
    }, {});
  } else return obj;
};

export default (body: any) => {
  if (!body) {
    return body;
  }
  return camelKeys(body);
};
