import xmljs from 'xml-js';
import { upperCaseFirst } from 'upper-case-first';
import { removeTypenames } from './utils';
import { identity } from 'lodash';

const convertBaseOptions = {
  compact: true,
  spaces: 2,
};

export type TransformXMLRequestOptions = {
  casing?: 'upperCaseFirst' | 'original';
  /** If true, don't apply XML transformer */
  skipXmlConversion?: boolean;
};

export default (
  body: any,
  options: TransformXMLRequestOptions = {},
): string => {
  // if someone passes a result from a query back to a mutation, it'll still
  // have __typename metadata on it. We can just remove that universally.
  const withoutExtras = removeTypenames(body);
  if (Object.keys(withoutExtras).length !== 1) {
    console.warn(
      `An object with multiple root keys was passed to the XML converter. This was probably not intentional. Check your mutation inputs and provide a single root value.`,
    );
  }
  const xml = xmljs.js2xml(withoutExtras, {
    ...convertBaseOptions,
    // These use upperCaseFirst so that ALLCAPS node names can be supported,
    // unless the user overrides to use their original casing.
    attributeNameFn: options.casing === 'original' ? identity : upperCaseFirst,
    elementNameFn: options.casing === 'original' ? identity : upperCaseFirst,
  });
  return xml;
};
