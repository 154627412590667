export const removeTypenames = (body: any): any => {
  if (!body || typeof body !== 'object') {
    return body;
  }

  if (body instanceof Date) {
    return body.toISOString();
  }

  if (body instanceof Array) {
    return body.map(removeTypenames);
  }

  const { __typename, ...rest } = body;
  return Object.keys(rest).reduce(
    (acc, key) => ({
      ...acc,
      [key]: removeTypenames(rest[key]),
    }),
    {},
  );
};
