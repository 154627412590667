import useSWR, { mutate, ConfigInterface, responseInterface } from 'swr';
import useBoundFetch, { BoundFetchResult } from '../useBoundFetch';
import { useMemo, useCallback } from 'react';
import { HookshotError } from '../../ForgeHooksProvider';

export interface UseReadOptions<R> {
  /** Allows you to skip the hook conditionally. */
  skip?: boolean;
  /** A function to transform the response data. */
  transformResponse?: (data: any) => R;
  /** A function to transform the error object. */
  transformError?: (error: HookshotError) => HookshotError;
  /** Options to pass into SWR. */
  swrOptions?: Partial<ConfigInterface>;
  /** Options to pass into the fetcher. */
  fetchOptions?: Partial<RequestInit>;
}

export interface UseReadResult<R>
  extends BoundFetchResult<R>,
    Omit<responseInterface<BoundFetchResult<R>, HookshotError>, 'data'> {
  refetch: () => Promise<RefetchResult<R>>;
  loading: boolean;
}

type RefetchResult<R> = Omit<BoundFetchResult<R>, 'error'>;

/**
 * The useRead hook allows you to fetch data and use that data once it loads. It allows you to easily handle the
 * loading state, store the response data, and request updates as needed.
 * @param url The URL that you want to fetch data from. Typically you should pass in `utils.makeUrl(url, vars)` here.
 * @param options Options to alter the way the fetcher and SWR work.
 * @example
 * ```tsx
 * const {data, loading, error} = useRead<ResponseType>(url, { transformResponse, ...readOptions })
 * ```
 * @see https://forge-hookshot-docs.apps.lab1.ocp.bandwidth.com/docs/custom-hooks#custom-read-hooks
 */
const useRead = <R = any>(
  url: string | null,
  options: UseReadOptions<R> = {},
): UseReadResult<R> => {
  const { transformResponse, transformError } = options;
  const boundFetch = useBoundFetch<R>(
    {
      transformResponse,
      transformError,
    },
    options.fetchOptions,
  );
  const { data, ...swrResult } = useSWR<BoundFetchResult<R>>(
    !(options && options.skip) && url,
    boundFetch,
    options.swrOptions,
  );

  const refetch = useCallback(async (): Promise<RefetchResult<R>> => {
    const { error, ...result } = (await mutate(
      url,
      boundFetch(url),
    )) as BoundFetchResult<R>;
    if (error) {
      throw error;
    }
    return result;
  }, [boundFetch, swrResult, swrResult.mutate, url]);

  /**
   * Loading state:  SWR's isValidating boolean is initially false, which is counterintuitive.
   *  We check for the existence of a response so that it will be true until the underlying request completes.
   *  Loading should also be false when options.skip is passed.
   *
   * Memoization:  Unfortunately, SWR's return object is fully stable, and is mutated between renders.
   *  This hook returns an object which has a new identity iff the status of the SWR hook has changed.
   */
  return useMemo(
    () => ({
      ...swrResult,
      ...data,
      loading: !(options && options.skip) && (!data || swrResult.isValidating),
      refetch,
    }),
    [swrResult, data, swrResult.isValidating, options, refetch],
  );
};

export default useRead;
