import { get } from 'lodash';
import qs from 'qs';

/**
 * Coerces value to an array.
 * @param node
 */
export const coerceToArray = <T>(node: T | T[]): T[] => {
  if (node instanceof Array) {
    return node;
  }
  if (node === null || node === undefined) {
    return [];
  }
  return [node];
};

/**
 * Coerces value to a Date() value.
 * @param node
 */
export const coerceToDate = (node: any) => {
  if (node === undefined || node === null || node === '') {
    return null;
  }
  return new Date(node);
};

/**
 * Coerces value to a Number value.
 * @param node
 */
export const coerceToNumber = (node: any) => {
  if (node === undefined || node === null || node === '') {
    return null;
  }
  return Number(node);
};

/**
 * Coerces value to a boolean value.
 * @param node
 */
export const coerceToBoolean = <T extends string>(node: T) => {
  const boolText = node && node.toLowerCase && node.toLowerCase();
  if (boolText === 'true') {
    return true;
  }
  if (boolText === 'false') {
    return false;
  }
  return node;
};

const traverse = (
  data: any,
  path: string[],
  conversionFunction: (value: any) => any,
) => {
  if (!data) return;
  const currentData = data[path[0]];
  if (path.length > 1) {
    if (Array.isArray(currentData)) {
      currentData.forEach(d => traverse(d, path.slice(1), conversionFunction));
    } else {
      traverse(currentData, path.slice(1), conversionFunction);
    }
  } else if (path.length === 1) {
    data[path[0]] = conversionFunction(data[path[0]]);
  } else {
    throw new Error('Empty path');
  }
};

/**
 * Converts a dot-separated string path to an array of strings.
 * @param path The path to convert.
 */
const handlePath = (path: string | string[]): string[] =>
  Array.isArray(path) ? path : path.split('.');

/**
 * Converts a path to an array. Mutate the incoming data
 * Automatically traverses any arrays that are encountered.
 * @param data The data to convert
 * @param path Path to the value
 */
export const convertArray = (data: any, path: string | string[]) => {
  traverse(data, handlePath(path), coerceToArray);
};

/**
 * Converts a path to a Date. Mutate the incoming data.
 * Automatically traverses any arrays that are encountered.
 * @param data The data to convert
 * @param path Path to the value
 */
export const convertDate = (data: any, path: string | string[]) => {
  traverse(data, handlePath(path), coerceToDate);
};

/**
 * Converts a path to a number. Mutate the incoming data.
 * Automatically traverses any arrays that are encountered.
 * @param data The data to convert
 * @param path Path to the value
 */
export const convertNumber = (data: any, path: string | string[]) => {
  traverse(data, handlePath(path), coerceToNumber);
};

/**
 * Converts a path to a boolean. Mutate the incoming data.
 * * Automatically traverses any arrays that are encountered.
 * @param data The data to convert
 * @param path Path to the value
 */
export const convertBoolean = (data: any, path: string | string[]) => {
  traverse(data, handlePath(path), coerceToBoolean);
};

export const unwrapObject = (obj: any, unwrapKey?: string) =>
  unwrapKey ? get(obj, unwrapKey) : obj;

/**
 * Removes the outermost key in an object, if it only has one key.
 * @param obj The object
 */
export const unwrapOutermostKey = (obj: any) => {
  if (!obj) {
    return obj;
  }
  const keys = Object.keys(obj);
  if (keys.length > 1) {
    console.warn('Tried to unwrap ', obj, ' with more than one key: ', keys);
  }
  return keys.length === 1 ? obj[keys[0]] : obj;
};

export const wrapObject = (obj: any, wrapKey?: string) =>
  wrapKey ? { [wrapKey]: obj } : obj;

function alphabeticalSort(a, b) {
  return a.localeCompare(b);
}

/**
 * Produces a URL from path by filling in parameters from vars.
 * @param path The string URL path. Supports replacement from vars
 * @param vars The object that include keys to replace in path.
 * Passing in `query` will automatically format a query string at
 * the end of the URL.
 * @example
 * ```tsx
 * const finalUrl = makeUrl('my/{id}', {id: 5});
 * ```
 */
export const makeUrl = (path: string, vars: any) => {
  let varMissing = false;
  let apiPath = path.replace(/\{([^}]*)\}/g, (match, varName) => {
    varMissing =
      varMissing ||
      !vars ||
      !Object.prototype.hasOwnProperty.call(vars, varName) ||
      vars[varName] === null ||
      vars[varName] === undefined;

    return vars && vars[varName];
  });
  if (varMissing) {
    return null;
  }
  if (vars.query) {
    apiPath += `?${qs.stringify(vars && vars.query, {
      sort: alphabeticalSort,
      skipNulls: true,
    })}`;
  }
  return apiPath;
};
